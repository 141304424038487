function initSwiperScreenshots() {    
	var swiperScreenshots = new Swiper(".swiper--screenshots", options);
}

var options = {
	spaceBetween: 30,
	// speed: 500,
	effect: "fade",
	crossFade: true,
	autoplay: {
			delay: 3000,
			disableOnInteraction: false,
	},
	loop: true,
};

// initSwiperScreenshots();

// Nastavení observeru na všechny sekce s carouselem
// document.querySelectorAll(".section--screenshots").forEach((section) => {
	
// 	if ($(section).find('.swiper--screenshots').length) {
// 			// console.log("Init swiperScreenshots");
// 			initSwiperScreenshots();
// 	}

// });

// Získání sekce a jejího textu bez jQuery
const screenshotsSections = document.querySelectorAll(".section--screenshots");
if (screenshotsSections) {

	screenshotsSections.forEach((section) => {

		// let screenshotsSectionsTitleText = section.querySelector(".observeThis")?.textContent || "";
		
		// Nastavení observeru
		section.addEventListener("contentChanged", (e) => {
			
				var swiper = e.detail.section.querySelector('.swiper-initialized');
				
				// const newText = e.detail.section.querySelector('.observeThis')?.textContent || "";

				if (!swiper) {
					new Swiper(e.detail.section.querySelector('.swiper--screenshots'), options);
				}
				
				// if (swiper && newText !== screenshotsSectionsTitleText) {
				// 	screenshotsSectionsTitleText = newText;						
				// 	// swiper.update();
				// }
		});

	});
};