// Funkce pro sledování změn v sekci
function observeSection(section) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "childList" || mutation.type === "subtree") {
        // console.log("Změna v DOM detekována.");
        // Vytvoření a vyvolání vlastního eventu
        const event = new CustomEvent("contentChanged", {
          detail: { section: section },
        });
        section.dispatchEvent(event);
      }
    });
  });

  observer.observe(section, {
    childList: true, // sleduje změny mezi dětmi
    subtree: true,   // sleduje všechny potomky
  });
}

// Nastavení observeru na všechny sekce - pinned
document.querySelectorAll(".section").forEach((section) => {
	observeSection(section);
});