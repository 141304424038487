function initTestimonials() {
	var swiperTestimonials = new Swiper("#swiperTestimonials", {
			// slidesPerView: 1,
			spaceBetween: 100,
			speed: 1000,
			autoplay: {
					delay: 5000,
					disableOnInteraction: false,
			},
			loop: true,
			pagination: {
					el: ".swiper-pagination",
					clickable: true
			},
	});
}
setTimeout(() => {
	initTestimonials();
}, 1000);

// Získání sekce a jejího textu bez jQuery
const testimonialsSection = document.getElementById("testimonials");
if (testimonialsSection) {
	let testimonialsSectionTitleText = testimonialsSection.querySelector(".observeThis")?.textContent || "";
	
	// Nastavení observeru
	testimonialsSection.addEventListener("contentChanged", (e) => {
			const newText = e.detail.section.querySelector('.observeThis')?.textContent || "";
			
			if (newText !== testimonialsSectionTitleText) {
					testimonialsSectionTitleText = newText;
					initTestimonials();
			}
	});
};

