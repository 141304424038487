import { init } from "aos";

function initChoices() {
    $('.choices-js').each(function() {
        const choices = new Choices(this, {
            searchEnabled: true,
            placeholder: true,
            searchPlaceholderValue: 'Search',
            itemSelectText: '',
            shouldSort: false,
        });
    })
}    

// Získání sekce a jejího textu bez jQuery
const contactSections = document.querySelectorAll(".section--contact")[0];
if (contactSections) {
    let contactSectionTitleText = contactSections.querySelector(".observeThis")?.textContent || "";
    
    // Nastavení observeru
    contactSections.addEventListener("contentChanged", (e) => {
        const newText = e.detail.section.querySelector('.observeThis')?.textContent || "";

        if (!e.detail.section.querySelector('.choices') && newText === contactSectionTitleText) {       
            setTimeout(() => {
                initChoices();
            }, 3000);
        }
        
        if (newText !== contactSectionTitleText) {
            contactSectionTitleText = newText;
            initChoices();
        }        

    });
};
