;(function ($) {

	'use strict';

	// gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

	// const mqLargeScreen = window.matchMedia( "(min-width: 992px)" );

	// if (mqLargeScreen.matches) {

	// 	gsap.to(section, {
	// 		scrollTrigger: {
	// 			trigger: section,
	// 			start: "top top",
	// 			end: `+=${steps * scrollingAreaOnOneStep}%`,
	// 			pin: true,
	// 			scrub: true,
	// 			onUpdate: handlePhoneSectionUpdate, // Funkce pro aktualizaci animace při scrollu
	// 		}
	// 	});
		
	// }

// test for position: sticky - zadny nadrizeny element nesmi mit overflow krom visible
// let parent = document.querySelector('.sticky').parentElement;
// while (parent) {
//   const hasOverflow = getComputedStyle(parent).overflow;
//   if (hasOverflow !== 'visible') {
//     console.log(hasOverflow, parent);
//   }
//   parent = parent.parentElement;
// }

	// var $pinnedSections = $('.section--pinned');
	function initPinnedSection() {	
		var waypointsPinnedDown = $('.pinned-slide-waypoint').waypoint(function(direction) {
			
			if (direction === 'down') {
				let $el = $(this.element);
				let index = $el.attr('data-content');

				let $items = $el.closest('.section--pinned').find('.pinnedContent__item');
				$items.removeClass('active');

				$items.eq(index - 1).addClass('active');
			}		
	
		}, {
			offset: '75%'
		});

		var waypointsPinnedUp = $('.pinned-slide-waypoint').waypoint(function(direction) {
			
			if (direction === 'up') {
				let $el = $(this.element);
				let index = $el.attr('data-content');

				let $items = $el.closest('.section--pinned').find('.pinnedContent__item');
				$items.removeClass('active');

				$items.eq(index - 1).addClass('active');
			}		
	
		}, {
			offset: '0%'
		});		

	}
	
	setTimeout(() => {
		initPinnedSection();
	}, 1000);	

	// Nastavení observeru na všechny sekce s pinned section
	document.querySelectorAll(".section--pinned").forEach((section) => {
		// observeSection(section);

		// Poslech vlastního eventu 'contentChanged' a reinicializace carouselu
		section.addEventListener("contentChanged", (e) => {
			// console.log("Event contentChanged odchycen. - pinned");
			initPinnedSection(); // nebo initCarousel(e.detail.section) pro specifickou sekci
		});
	});

	function isViewportInsideElement(element) {
		const rect = element.getBoundingClientRect();
		const viewportHeight = window.innerHeight;
	
		// Podmínka ověří, zda je horní část elementu nad vrškem viewportu a spodní část pod spodkem viewportu
		return rect.top < 0 && rect.bottom > viewportHeight;
	}
	
	// Příklad použití s event listenerem při scrollování
	window.addEventListener('scroll', () => {
		const elements = $('.section--pinned');
		for (var i = 0; i < elements.length; i++) {
			var element = elements[i];
			if (isViewportInsideElement(element)) {
				// console.log('Viewport je uvnitř elementu');
				$(element).addClass('is-pinned');
			} else {
				// console.log('Viewport není uvnitř elementu');
				$(element).removeClass('is-pinned');
			}
		}
	});

})(jQuery);